export function insights(data, accuracy) {
  const mean = data.reduce((acc, val) => acc + val, 0) / data.length
  const variance = data.reduce((acc, val) => acc + (val - mean) ** 2, 0) / data.length
  const standardDeviation = Math.sqrt(variance)
  return [
    { title: 'Min', value: Math.min(...data).toFixed(2) },
    { title: 'Max', value: Math.max(...data).toFixed(2) },
    {
      title: 'Avg', value: (data.reduce((a, b) => a + b, 0) / data.length).toFixed(2),
    },
    {
      title: 'Accuracy',
      value: `${accuracy.toFixed(2)}%`,
    },

    { title: 'Median', value: (data.sort((a, b) => a - b)[Math.floor(data.length / 2)]).toFixed(2) },
    {
      title: 'Variance',
      value: variance.toFixed(2),
    }, {
      title: 'Standard Deviation',
      value: standardDeviation.toFixed(2),
    }, {
      title: 'Mean',
      value: mean.toFixed(2),
    }]
}

export function mapCorrectAnswers(data) {
  return [{
    label: 'Correct Answers',
    value: data.filter(item => item.CorrectAnswer === item.UserChoice).length,
  }, {
    label: 'Incorrect Answers',
    value: data.filter(item => item.CorrectAnswer !== item.UserChoice).length,
  },
  {
    label: 'Unanswered',
    value: data.filter(item => item.UserChoice === null).length,
  },
  ]
}

export function mapAnswerTime(data) {
  return data.map(item => ({
    value: item.AnswerTime / 1000,
    label:
        item.questionId?.title?.en,
  }))
}

export function mapHistogram(data) {
  const ranges = [...new Set(data.map(item => Math.floor(item.value)).sort((a, b) => a - b))]
  if (ranges.length % 2 !== 0) ranges.push(ranges[ranges.length - 1] + 1)
  const splitRanges = ranges.reduce((result, value, index, array) => {
    if (index % 2 === 0) result.push(array.slice(index, index + 2))
    return result
  }, [])
  const values = data.map(item => item.value)
  const chartData = { datasets: [{ backgroundColor: [], data: [], label: 'Answers' }], labels: [] }
  splitRanges.forEach((range, index) => {
    const green = Math.floor((index * 60) % 256).toString(16).padStart(2, '0')
    const blue = Math.floor((index * 60) % 256).toString(16).padStart(2, '0')
    values.sort((a, b) => a - b)
    chartData.labels.push(`${range[0]} - ${range[1]}`)
    chartData.datasets[0].backgroundColor.push(`#ff${green}${blue}`)
    chartData.datasets[0].data.push(values.filter(value => (value >= range[0] && value < range[1] ? value : 0)).length)
  })
  return chartData
}

export function getAnswersFromQuestions(research) {
  const answers = research.map(item => item.Questions)
  return [].concat(...answers)
}

export function getCorrectAnswersAccuracy(answers) {
  return (answers.filter(item => item.CorrectAnswer === item.UserChoice).length / answers.length) * 100
}
