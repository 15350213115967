<script>

import Statistics from '@/components/Statistics/Statistics.vue'
import { getQuestionAnswersByResearchId, getResearchAnswersByResearchId } from '@/api/answers'
import {
  getAnswersFromQuestions,
  getCorrectAnswersAccuracy,
  mapAnswerTime,
  mapCorrectAnswers,
  mapHistogram,
} from '@/components/Statistics/utils'

export default {
  name: 'StatisticsPage',
  components: { Statistics },
  props: {
    researchId: {
      type: String,
      required: true,
    },
    entryId: {
      type: String,
      required: false,
      default: null,
    },
    userId: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      answerTime: [],
      correctAnswers: [],
      accuracy: 0,
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    mapHistogram,
    async fetchData() {
      let response
      if (this.userId) {
        response = await getQuestionAnswersByResearchId(this.entryId)
      } else {
        response = await getResearchAnswersByResearchId(this.researchId)
        response = getAnswersFromQuestions(response)
      }
      this.answerTime = mapAnswerTime(response)
      this.correctAnswers = mapCorrectAnswers(response)
      this.accuracy = getCorrectAnswersAccuracy(response)
    },
  },
}
</script>

<template>
  <div>
    <Statistics
      v-if="answerTime.length"
      type="line"
      :data="answerTime"
      :accuracy="accuracy"
      title="Answer Time"
    />
    <Statistics
      v-if="correctAnswers.length"
      type="pie"
      title="Correct Answers"
      :show-insights="false"
      :data="correctAnswers"
    />
    <Statistics
      v-if="answerTime.length"
      type="histogram"
      :show-insights="false"
      title="Answer Time Histogram"
      :data="mapHistogram(answerTime)"
    />

  </div>
</template>
