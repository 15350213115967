<template>
  <div class="insight-item">
    <div class="title">
      {{ insight.title }}
    </div>
    <div class="text">
      {{ insight.value }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'InsightItem',
  props: {
    insight: {
      type: Object,
      required: true,
    },
  },
}
</script>

<style scoped>
.insight-item {
  border: 1px solid #ccc;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.title {
  font-size: 1.2em;
  font-weight: bold;
  margin-bottom: 8px;
}

.text {
  font-size: 1em;
}
</style>
