/* global Backendless */

export async function getQuestionAnswersByResearchId(researchId) {
  const whereClause = `ResearchAnswers[Questions].objectId = '${researchId}'`
  const queryBuilder = Backendless.DataQueryBuilder.create()
  queryBuilder.setRelationsDepth(2)
  queryBuilder.setPageSize(100).setOffset(0)
  queryBuilder.setRelationsPageSize(100)
  queryBuilder.setWhereClause(whereClause)
  const query = Backendless.Data.of('QuestionsAnswers')
  return query.find(queryBuilder)
}

export async function getResearchAnswersByResearchId(researchId) {
  const whereClause = `ResearchId = '${researchId}'`
  const queryBuilder = Backendless.DataQueryBuilder.create()
  queryBuilder.setRelationsDepth(2)
  queryBuilder.setPageSize(100).setOffset(0)
  queryBuilder.setRelationsPageSize(100)
  queryBuilder.setWhereClause(whereClause)
  const query = Backendless.Data.of('ResearchAnswers')
  return query.find(queryBuilder)
}
