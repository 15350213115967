<script>
import { BCol, BRow } from 'bootstrap-vue'
import InsightItem from '@/components/Statistics/InsightItem.vue'
import LineChart from '@/components/Statistics/charts/Line.vue'
import PieChart from '@/components/Statistics/charts/Pie.vue'
import Histogram from '@/components/Statistics/charts/Histogram.vue'
import { insights } from '@/components/Statistics/utils'

export default {
  name: 'Statistics',
  components: {
    Histogram,
    PieChart,
    LineChart,
    InsightItem,
    BCol,
    BRow,
  },
  props: {
    title: {
      type: String,
      default: 'Statistics',
    },
    data: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      default: 'line',
    },
    showInsights: {
      type: Boolean,
      default: true,
    },
    accuracy: {
      type: Number,
      default: 0,
    },
  },
  methods: { insights },
}
</script>

<template>
  <div class="my-3">
    <div v-if="showInsights">
      <BRow>
        <BCol>
          <h2>Insights</h2>
          <h3 class="my-2">
            {{ title }}
          </h3>
        </BCol>
      </BRow>
      <BRow>
        <BCol
          v-for="insight in insights(data.map(item => item.value), accuracy)"
          :key="insight.title"
          :sm="2"
          :md="4"
          :lg="3"
        >
          <InsightItem :insight="insight" />
        </BCol>
      </BRow>
    </div>
    <BRow>
      <BCol>
        <BCard>
          <BCardHeader>
            <h4>{{ title }} Statistics</h4>
          </BCardHeader>
          <BCardBody>

            <LineChart
              v-if="type === 'line'"
              :data="data"
            />
            <PieChart
              v-if="type === 'pie'"
              :data="data"
            />
            <Histogram
              v-if="type === 'histogram'"
              :data="data"
            />
          </BCardBody>
        </bcard>
      </BCol>
    </Brow>
  </div>
</template>
